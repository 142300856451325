import { ChangeDetectionStrategy, Component, computed, inject, output, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TenantDetails } from 'consistent-api-nvx-internal-sdk-dev';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { NotificationsService } from '@/app/services/notifications.service';
import { UserPreferenceStore } from '@/app/stores/user-preference.store';
import { NVXRole } from '@/app/types/nvx-role.enum';
import { AvatarComponent, IconComponent } from '@/shared/components';
import { TenantStore, UserStore } from '@/shared/stores';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent, ButtonModule, IconComponent, ImageModule, MenuModule, RouterModule, TooltipModule],
  selector: 'nvx-layout-sidebar',
  standalone: true,
  styleUrl: './sidebar.component.css',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent {
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  userPreferenceStore = inject(UserPreferenceStore);
  notificationsService = inject(NotificationsService);

  onLogout = output();

  unreadNotifications = signal(false);

  isSideNavCollapsed = computed(() => this.userPreferenceStore.isSideNavCollpased());

  tenantOptions = computed<MenuItem[] | undefined>(() => {
    return this.userStore.tenants()?.map((tenant) => {
      return {
        id: tenant.tenantId,
        label: tenant.tenantName,
        command: () => {
          this.onTenantChange({
            tenantId: tenant.tenantId,
            tenantName: tenant.tenantName,
          });
        },
      };
    });
  });

  roleBasedNavLinks = computed(() => {
    const activeRoles = this.tenantStore.userRoles();
    return this.navLinks.filter((navLink) => activeRoles.includes(navLink.role));
  });

  organizations = [
    {
      id: 'oostvogels',
      name: 'Oostvogels',
      logo: 'assets/logos/orgs/large/oostvogels.svg',
      favicon: 'assets/logos/orgs/small/oostvogels.svg',
      class: 'oostvogels',
      version: 2,
      address: 'f63e7260-722b-4f45-b706-a108c6465941',
    },
  ];

  onTenantChange(tenant: TenantDetails) {
    this.tenantStore.setActiveTenant(tenant);
    this.tenantStore.setUserRoles(this.userStore.currentUser()?.tenantRoles);
    window.location.reload();
  }

  toggleSideNav = () => {
    this.userPreferenceStore.toggleSideNav();
  };

  logout = () => {
    this.onLogout.emit();
  };

  navLinks = [
    {
      label: 'Home',
      icon: 'mdi:home-outline',
      to: '/home',
      role: NVXRole.FE_DEFAULT,
    },
    {
      label: 'Notifications',
      icon: 'mdi:bell-outline',
      to: '/notifications',
      role: NVXRole.FE_NOTIFICATIONREAD,
    },
    {
      label: 'Smart Tasks',
      icon: 'material-symbols:widgets-outline',
      to: '/smart-tasks',
      role: NVXRole.FE_SMARTTASKREAD,
    },
    {
      label: 'Shipments',
      icon: 'fluent:vehicle-ship-24-regular',
      to: '/shipments',
      role: NVXRole.FE_SHIPMENTINBOUNDREAD,
    },
    {
      label: 'New Shipment',
      icon: 'ic:outline-add-location-alt',
      to: '/shipments/sea/fcl/new',
      role: NVXRole.FE_NEWSHIPMENTREAD,
    },
    {
      label: 'Address Book',
      icon: 'ic:outline-menu-book',
      to: '/address-book',
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
    {
      label: 'Stocks',
      icon: 'clarity:container-line',
      to: '/stocks',
      role: NVXRole.FE_STOCKOVERVIEWREAD,
    },
    {
      label: 'Billing',
      icon: 'ic:outline-money',
      to: '/billing/invoices',
      role: NVXRole.FE_BILLINGREAD,
    },
  ];
}
