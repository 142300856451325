<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<aside
  class="relative flex h-full flex-col bg-primary text-white transition-all duration-300"
  [class]="isSideNavCollapsed() ? 'w-20' : 'w-64'"
>
  <div
    class="flex w-full items-center"
    [class]="isSideNavCollapsed() ? 'flex-col justify-center py-4 gap-3.5' : 'flex-row justify-between py-5 px-7'"
  >
    <p-image
      alt="NVX Logo"
      src="/assets/logos/nvx_logo.png"
      [styleClass]="isSideNavCollapsed() ? 'flex h-5 w-11' : 'flex h-7 w-16'"
    />

    <!-- TODO DATA-1529: Disable the Brand tenant selector for the time being -->
    <!--<p-menu #menuOrg styleClass="text-white bg-primary-light border-none" [model]="organizations" [popup]="true">
      <ng-template let-item pTemplate="item">
        <div
          class="my-1 cursor-pointer rounded-md px-3 py-2 text-white"
          [class]="tenantStore.activeTenantId === item.id ? 'bg-primary' : 'bg-primary-light hover:bg-primary/50'"
        >
          <p-image class="flex justify-center" [src]="item.logo" />
        </div>
      </ng-template>
    </p-menu>
    <p-button
      styleClass="py-1.5 px-2.5 gap-2 text-white w-full rounded-md bg-primary-light border-none flex items-center justify-between"
      [rounded]="true"
      (onClick)="menuOrg.toggle($event)"
    >
      <p-image imageClass="h-5" [src]="organizations[0]['favicon']" />
      @if (!isSideNavCollapsed()) {
        <nvx-ui-icon class="text-sm font-bold" icon="ion:chevron-down" />
      }
    </p-button>-->
  </div>

  <div class="h-px bg-primary-light" [class]="isSideNavCollapsed() ? 'mx-0' : 'mx-6'"></div>

  <div class="flex flex-col gap-1" [class]="isSideNavCollapsed() ? 'pt-2 pb-4 px-2' : 'p-6'">
    @if (!isSideNavCollapsed()) {
      <div class="cursor-pointer rounded-md bg-primary-light px-3 py-2" routerLink="/user/profile">
        <div class="flex items-center gap-3">
          <nvx-ui-avatar
            class="size-12 text-xl"
            [firstName]="userStore.firstName()"
            [lastName]="userStore.userProfile()?.lastname"
            [profilePictureId]="userStore.userProfile()?.profilePictureId"
          />
          <div class="flex flex-col gap-0.5">
            <div class="text-sm font-semibold" data-testId="firstName">{{ userStore.firstName() }}</div>
            <div class="text-xs text-zinc-300">User</div>
          </div>
        </div>
      </div>
    } @else {
      <div class="flex items-center justify-center py-1">
        <div class="cursor-pointer" routerLink="/user/profile">
          <nvx-ui-avatar
            class="block size-14 text-2xl"
            [firstName]="userStore.firstName()"
            [lastName]="userStore.userProfile()?.lastname"
            [profilePictureId]="userStore.userProfile()?.profilePictureId"
          />
        </div>
      </div>
    }
    <p-menu
      #menu
      styleClass="text-white min-w-60 bg-primary-light border-none"
      [model]="tenantOptions()"
      [popup]="true"
    >
      <ng-template let-item pTemplate="item">
        <div
          class="my-1 rounded-md px-3 py-2 text-sm text-white"
          [class]="tenantStore.activeTenantId() === item.id ? 'bg-primary' : 'bg-primary-light hover:bg-primary/50'"
        >
          {{ item.label }}
        </div>
      </ng-template>
    </p-menu>
    <p-button
      styleClass="px-3 py-2 text-sm text-white w-full rounded-md bg-primary-light border-none flex items-center justify-between"
      [rounded]="true"
      (onClick)="menu.toggle($event)"
    >
      @if (isSideNavCollapsed()) {
        {{ tenantStore.activeTenantName()?.slice(0, 1) ?? '' }}...
      } @else {
        {{ tenantStore.activeTenantName() }}
      }
      <nvx-ui-icon class="text-sm font-bold" icon="ion:chevron-down" />
    </p-button>
  </div>

  <div class="h-px bg-primary-light" [class]="isSideNavCollapsed() ? 'mx-0' : 'mx-6'"></div>

  <div class="flex h-full flex-col justify-between overflow-y-auto overflow-x-hidden border-none py-4">
    <ul>
      @for (link of roleBasedNavLinks(); track link.label) {
        <li
          class="flex cursor-pointer items-center gap-3 hover:bg-primary-light/50"
          tooltipStyleClass="text-sm"
          [class]="isSideNavCollapsed() ? 'justify-center px-2 py-3' : 'px-6 py-3'"
          [pTooltip]="isSideNavCollapsed() ? link.label : undefined"
          [routerLink]="link.to"
          [routerLinkActive]="'!bg-primary-light'"
        >
          <div class="relative">
            <nvx-ui-icon class="text-2xl" [icon]="link.icon" />
            @if (link.label === 'Notifications' && unreadNotifications()) {
              <div class="absolute right-0.5 top-0.5 size-2 rounded-full bg-red-500"></div>
            }
          </div>
          @if (!isSideNavCollapsed()) {
            <span class="text-sm tracking-wide">{{ link.label }}</span>
          }
        </li>
      }
    </ul>
  </div>
  <div class="sticky bottom-0 mt-2 flex w-full flex-col">
    <p-button
      styleClass="items-center justify-center w-full rounded-none border-primary-light gap-1 py-[13.5px] px-0 hover:bg-primary-light/50"
      tooltipStyleClass="text-sm"
      [pTooltip]="isSideNavCollapsed() ? 'Logout' : undefined"
      (onClick)="logout()"
    >
      @if (!isSideNavCollapsed()) {
        <span class="text-sm">Logout</span>
      }
      <nvx-ui-icon class="text-xl" icon="ri:shut-down-line" />
    </p-button>
    <p-button
      styleClass="items-center justify-center rounded-none border-none size-full gap-1 p-0 bg-primary-light"
      tooltipStyleClass="text-sm"
      [pTooltip]="isSideNavCollapsed() ? 'Version 01.08.24' : undefined"
    >
      @if (!isSideNavCollapsed()) {
        <span class="text-[14px]">Version 01.08.24</span>
      } @else {
        <span class="text-[14px]">Vers...</span>
      }
    </p-button>
  </div>
  <p-button
    class="absolute bottom-32 right-0 z-10 translate-x-1/2 rounded-full bg-primary-light"
    styleClass="p-1.5 text-white"
    [rounded]="true"
    [text]="true"
    (onClick)="toggleSideNav()"
  >
    <nvx-ui-icon class="font-bold" [icon]="isSideNavCollapsed() ? 'ion:chevron-right' : 'ion:chevron-left'" />
  </p-button>
</aside>
