import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { NVXRole } from '@/app/types/nvx-role.enum';
import { NVXRoute } from '@/app/types/nvx-route.type';
import { TenantStore } from '@/shared/stores';

const homePages: NVXRoute[] = [
  {
    path: '',
    redirectTo: '/home',
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
  {
    path: 'home',
    loadComponent: () => import('@/app/pages/home/home.component').then((c) => c.HomeComponent),
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
];

const userPages: NVXRoute[] = [
  {
    path: 'user',
    redirectTo: '/user/profile',
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
  {
    path: 'user/profile',
    loadComponent: () => import('@/app/pages/user/profile/user-profile.component').then((c) => c.UserProfileComponent),
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
];

const notificationsPages: NVXRoute[] = [
  {
    path: 'notifications',
    loadComponent: () =>
      import('@/app/pages/notifications/notifications.component').then((c) => c.NotificationsComponent),
    data: {
      role: NVXRole.FE_NOTIFICATIONREAD,
    },
  },
];

const smartTasksListPages: NVXRoute[] = [
  {
    path: 'smart-tasks',
    loadComponent: () => import('@/app/pages/smart-tasks/smart-tasks.component').then((c) => c.SmartTasksComponent),
    data: {
      role: NVXRole.FE_SMARTTASKREAD,
    },
  },
];

const shipmentListPages: NVXRoute[] = [
  {
    path: 'shipments',
    redirectTo: '/shipments/containers',
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
  {
    path: 'shipments/inbound',
    loadComponent: () =>
      import('@/app/pages/shipments/inbound/shipments-inbound.component').then((c) => c.ShipmentsInboundComponent),
    data: {
      role: NVXRole.FE_SHIPMENTINBOUNDREAD,
    },
  },
  {
    path: 'shipments/containers',
    loadComponent: () =>
      import('@/app/pages/shipments/containers/shipments-containers.component').then(
        (c) => c.ShipmentsContainersComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCONTAINERREAD,
    },
  },
  {
    path: 'shipments/containers/:id',
    loadComponent: () =>
      import('@/app/pages/shipments/containers/shipments-containers.component').then(
        (c) => c.ShipmentsContainersComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCONTAINERREAD,
    },
  },
  {
    path: 'shipments/sea',
    loadComponent: () =>
      import('@/app/pages/shipments/sea/shipments-sea.component').then((c) => c.ShipmentsSeaComponent),
    data: {
      role: NVXRole.FE_SHIPMENTOCEANREAD,
    },
  },
  {
    path: 'shipments/road',
    loadComponent: () =>
      import('@/app/pages/shipments/road/shipment-road.component').then((c) => c.ShipmentsRoadComponent),
    data: {
      role: NVXRole.FE_SHIPMENTROADREAD,
    },
  },
];

const shipmentPages: NVXRoute[] = [
  {
    path: 'shipment/sea/fcl/new',
    loadComponent: () =>
      import('@/app/components/shipments/sea/new-sea-shipment-fcl/new-sea-shipment-fcl.component').then(
        (c) => c.NewSeaShipmentFclComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/sea/fcl/new/:id',
    loadComponent: () =>
      import('@/app/components/shipments/sea/new-sea-shipment-fcl/new-sea-shipment-fcl.component').then(
        (c) => c.NewSeaShipmentFclComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/road/outbound/new',
    loadComponent: () =>
      import('@/app/components/shipments/road/new-road-shipment-outbound/new-road-shipment-outbound.component').then(
        (c) => c.NewRoadShipmentOutboundComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/road/outbound/new/:id',
    loadComponent: () =>
      import('@/app/components/shipments/road/new-road-shipment-outbound/new-road-shipment-outbound.component').then(
        (c) => c.NewRoadShipmentOutboundComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/road/only-transport/new',
    loadComponent: () =>
      import(
        '@/app/components/shipments/road/new-road-shipment-only-transport/new-road-shipment-only-transport.component'
      ).then((c) => c.NewRoadShipmentOnlyTransportComponent),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/road/only-transport/new/:id',
    loadComponent: () =>
      import(
        '@/app/components/shipments/road/new-road-shipment-only-transport/new-road-shipment-only-transport.component'
      ).then((c) => c.NewRoadShipmentOnlyTransportComponent),
    data: {
      role: NVXRole.FE_SHIPMENTCREATE,
    },
  },
  {
    path: 'shipment/sea/:id',
    loadComponent: () =>
      import('@/app/pages/shipments/sea/details/sea-shipment-details.component').then(
        (c) => c.SeaShipmentDetailsComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTOCEANREAD,
    },
  },
  {
    path: 'shipment/road/:id',
    loadComponent: () =>
      import('@/app/pages/shipments/road/details/road-shipment-details.component').then(
        (c) => c.RoadShipmentDetailsComponent
      ),
    data: {
      role: NVXRole.FE_SHIPMENTROADREAD,
    },
  },
];

const addressBookListPages: NVXRoute[] = [
  {
    path: 'address-book',
    redirectTo: '/address-book/organizations',
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
  {
    path: 'address-book/organizations',
    loadComponent: () =>
      import('@/app/pages/address-book/organizations/organization-list.component').then(
        (c) => c.OrganizationListComponent
      ),
    data: {
      role: NVXRole.FE_ADDRESSBOOKORGANIZATIONS,
    },
  },
  {
    path: 'address-book/addresses',
    loadComponent: () =>
      import('@/app/pages/address-book/addresses/address-list.component').then((c) => c.AddressListComponent),
    data: {
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
  },
  {
    path: 'address-book/addresses/edit/:id',
    loadComponent: () =>
      import('@/app/pages/address-book/edit-address/edit-address.component').then((c) => c.EditAddressComponent),
    data: {
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
  },
  {
    path: 'address-book/addresses/new',
    loadComponent: () =>
      import('@/app/pages/address-book/new-address/new-address.component').then((c) => c.NewAddressComponent),
    data: {
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
  },
  {
    path: 'address-book/addresses/:id',
    loadComponent: () =>
      import('@/app/pages/address-book/addresses/address-list.component').then((c) => c.AddressListComponent),
    data: {
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
  },
];

const addressBookPages: NVXRoute[] = [
  {
    path: 'address-book/organizations/new',
    loadComponent: () =>
      import('@/app/pages/address-book/new-organization/new-organization.component').then(
        (c) => c.NewOrganizationComponent
      ),
    data: {
      role: NVXRole.FE_ADDRESSBOOKORGANIZATIONS,
    },
  },
  {
    path: 'address-book/organizations/:id',
    loadComponent: () =>
      import('@/app/pages/address-book/edit-organization/edit-organization.component').then(
        (c) => c.EditOrganizationComponent
      ),
    data: {
      role: NVXRole.FE_ADDRESSBOOKORGANIZATIONS,
    },
  },
];

const stocksPages: NVXRoute[] = [
  {
    path: 'stocks',
    loadComponent: () => import('@/app/pages/stocks/stocks.component').then((c) => c.StocksComponent),
    data: {
      role: NVXRole.FE_STOCKOVERVIEWREAD,
    },
  },
];

const billingPages: NVXRoute[] = [
  {
    path: 'billing',
    redirectTo: '/billing/invoices',
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
  {
    path: 'billing/invoices',
    loadComponent: () =>
      import('@/app/pages/billing/invoices/billing-invoices.component').then((c) => c.BillingInvoicesComponent),
    data: {
      role: NVXRole.FE_BILLINGREAD,
    },
  },
];

const errorPages: NVXRoute[] = [
  {
    path: '**',
    loadComponent: () => import('@/app/pages/error/error.component').then((c) => c.ErrorComponent),
    data: {
      role: NVXRole.FE_DEFAULT,
    },
  },
];

export const routes: NVXRoute[] = [
  ...homePages,
  ...userPages,
  ...smartTasksListPages,
  ...notificationsPages,
  ...shipmentListPages,
  ...shipmentPages,
  ...addressBookListPages,
  ...stocksPages,
  ...billingPages,
  ...addressBookPages,
  ...errorPages,
].map((page) => {
  if (page.redirectTo) {
    return {
      ...page,
      pathMatch: page.pathMatch ?? 'full',
    };
  } else {
    return {
      ...page,
      canActivate: [
        MsalGuard,
        () => {
          const role = page.data.role;
          const router = inject(Router);
          const tenantStore = inject(TenantStore);
          const isAuthenticated = tenantStore.activeTenant();
          // if unauthenticated (tenant not set), proceed as MSALGuard redirects to Azure B2C
          if (!isAuthenticated) {
            return true;
          }
          if (tenantStore.hasUserRole(role)) {
            return true;
          } else {
            router.navigateByUrl('');
            return false;
          }
        },
      ],
    };
  }
});
